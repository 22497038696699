<template>
  <div></div>
</template>

<script>
import {
  BASE_URL,
  TOKEN,
  APPID,
  REDIRECT_URI,
  getWxCode,
  tokenName,
  getToken,
  setLocalStorageByNames,
  removeLocalStorageByNames,
  getQueryString,
  STORAGE_ROOM_INFO,
  STORAGE_USER_INFO,
  STORAGE_MENU_LIST,
} from "../utils/public";
import Vue from "vue";
import { Toast } from "vant";
import { wxLogin } from "../api/user";
Vue.use(Toast);
export default {
  name: "Entrance",
  data() {
    return {};
  },
  mounted() {
  
  },
  methods: {
    controlRout(error) {},
  },
};
</script>

<style scoped>
</style>